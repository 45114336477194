<template>
  <div id="ranking" class="people">
    <section id="wrapper">
      <header>
        <div class="inner">
          <h2>Hall of Fame</h2>
          <p>Classifica marcatori</p>
        </div>
      </header>
      <div class="wrapper">
        <div class="inner">
          <section class="person_list">
            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>marcatore</th>
                    <th style="text-align: center">gol</th>
                    <th style="text-align: center">stagione</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(p, index) in ranking.players" :key="p.id">
                    <td class="person_ranking">{{ index + 1 }}</td>
                    <td class="person_image">
                      <img
                        :srcset="image_urls(people[p.id].image_id)"
                        :src="image_url(people[p.id].image_id)"
                        :alt="person_name(people[p.id])"
                      />
                    </td>
                    <td class="person_info">
                      <b>{{ person_name(people[p.id]) }}</b>
                      <br />
                      <a
                        v-if="people[p.id].url"
                        :href="info_url + people[p.id].url"
                        target="_blank"
                      >
                        <i class="fa fa-info-circle" />info
                      </a>
                    </td>
                    <td class="person_score">
                      <b>{{ p.score }}</b>
                    </td>
                    <td class="person_variation">
                      {{ p.variation > 0 ? '(+' + p.variation + ')' : '---' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="align-center table_info">
              classifica aggiornata al
              {{ new Date(ranking.last_update).toLocaleDateString('en-GB') }} <br /><br />
              nella classifica sono presenti i giocatori con un numero di gol maggiore o uguale a 3
            </p>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import people from '../../assets/data/people.json';
import ranking from '../../assets/data/ranking.json';

export default {
  name: 'Ranking',
  metaInfo: {
    title: 'Classifica',
  },
  data() {
    return {
      people: people,
      ranking: ranking,
      info_url: 'https://www.marcheingol.it/soggetto/',
      image_path: '/images/people/',
      image_res: [512, 1024],
    };
  },
  methods: {
    image_urls(id) {
      if (id == null) return this.image_path + 'empty.svg';
      return this.image_res
        .map((res) => this.image_path + res + '/' + id + '.jpg ' + res + 'w')
        .join(',');
    },
    image_url(id) {
      if (id == null) return this.image_path + 'empty.svg';
      return this.image_path + this.image_res[0] + '/' + id + '.jpg';
    },
    person_name(person) {
      if (person == null) return '';
      return person.last_name + ' ' + person.first_name;
    },
  },
};
</script>

<style scoped>
/* spostare in css condiviso */
.people .wrapper .inner {
  padding: 0.5em;
}
.people select {
  margin-bottom: 2em;
}
.people table td {
  vertical-align: middle;
  padding: 1em 0.5em;
}
.person table td.person_ranking {
  width: 2rem;
}
.people table td.person_image {
  width: 10em;
}
.people table td.person_image img {
  width: 8em;
}
.people table td.person_info a {
  font-size: 0.8rem;
}
.people table td.person_info a i {
  margin-right: 0.5em;
}
.people table td.person_score {
  text-align: center;
}
.people table td.person_variation {
  text-align: center;
}

@media (max-width: 480px) {
  .people table td.person_image {
    width: 7em;
  }
  .people table td.person_image img {
    width: 5em;
  }
}
</style>
