<template>
  <nav id="menu">
    <div class="inner">
      <h2>Menu</h2>
      <ul class="links">
        <li>
          <router-link :to="{ name: 'home' }" @click.native="hideMenu"
            >Home</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'sponsors' }" @click.native="hideMenu"
            >Sponsor</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'players' }" @click.native="hideMenu"
            >Rosa calciatori</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'staff' }" @click.native="hideMenu"
            >Organico</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'ranking' }" @click.native="hideMenu"
            >Classifica</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'gallery' }" @click.native="hideMenu"
            >Gallery</router-link
          >
        </li>
      </ul>
      <a class="close" @click="hideMenu">Close</a>
    </div>
  </nav>
</template>

<script>
export default {
  name: "PageMenu",
  data() {
    return {};
  },
  methods: {
    hideMenu() {
      let body = document.getElementById("body");
      body.classList.remove("is-menu-visible");
    },
  },
};
</script>