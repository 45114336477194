export const sponsors = [
  {
    id: 1,
    name: 'Bibite Tesino',
    description: "Distribuzione all'ingrosso di bevande",
    image: 'bibite_tesino.png',
    active: false,
  },
  {
    id: 2,
    name: 'CIMA',
    description: 'Commercio Ingrosso Materie Alimentari',
    image: 'cima.png',
    url: 'https://www.cimacima.net',
    active: false,
  },
  {
    id: 3,
    name: 'Claudio Ciabò',
    description: 'Confezioni conto terzi',
    image: 'claudio_ciabo.png',
    active: true,
  },
  {
    id: 4,
    name: 'Cruciani David',
    description: 'Impresa edile',
    image: 'cruciani_david.png',
    url: 'https://crucianidavid.it',
    active: false,
  },
  {
    id: 5,
    name: 'Enzo Gregori',
    description: 'Trasporti, spedizioni, magazzini, depositi frigoriferi',
    image: 'enzo_gregori.png',
    url: 'https://www.paginegialle.it/rubbianello-fm/corrieri/gregori-enzo',
    active: true,
  },
  {
    id: 6,
    name: 'Inverso',
    description: 'Studio creativo',
    image: 'inverso.png',
    url: 'http://studioinverso.com/',
    active: false,
  },
  {
    id: 7,
    name: 'L.E.S.A.F. Sas',
    description: 'Lavori edili, fognature e movimento terra',
    image: 'lesaf.png',
    active: true,
  },
  {
    id: 8,
    name: 'Medical Service di Sergio Basili',
    description: 'Prodotti farmaceutici',
    image: 'medical_service.png',
    active: true,
  },
  {
    id: 9,
    name: 'Merlonghi Francesco',
    description: 'Impresa edile',
    image: 'merlonghi_francesco.png',
    active: true,
  },
  {
    id: 10,
    name: 'Moretti Design',
    description: 'Stufe a pellet e legna',
    image: 'moretti_design.png',
    url: 'https://www.morettidesign.it',
    active: true,
  },
  {
    id: 11,
    name: 'Nuova elettroimpianti Eleuteri',
    description: 'Realizzazione impianti elettrici',
    image: 'nuova_elettroimpianti.png',
    active: false,
  },
  {
    id: 12,
    name: 'Parissi Tonino',
    description: 'Manutenzione giardini e parchi',
    image: 'parissi_tonino.png',
    active: false,
  },
  {
    id: 13,
    name: 'Ditta Pizi Luisa',
    description: 'Confezione abbigliamento e accessori',
    image: 'pizi_luisa.png',
    active: true,
  },
  {
    id: 14,
    name: 'Poliuretech S.r.l.',
    description: 'Componenti meccanici in poliuretano',
    image: 'poliuretech.png',
    url: 'https://www.polyuretech.com',
    active: true,
  },
  {
    id: 15,
    name: 'Romano Sacripanti',
    description: 'Attrezzature e ricambi per cave di marmo e granito',
    image: 'romano_sacripanti.png',
    url: 'https://lusacripanti.wixsite.com/romano1',
    active: false,
  },
  {
    id: 16,
    name: 'Rossetti Costruzioni',
    description: 'Impresa edile',
    image: 'rossetti_costruzioni.png',
    url: 'http://www.rossetticostruzioni.it',
    active: true,
  },
  {
    id: 17,
    name: 'Rutolo Alessandra',
    description: 'Negozio di alimentari',
    image: 'alimentari_rutolo.png',
    active: false,
  },
  {
    id: 18,
    name: 'Siquini Costruzioni',
    description: 'Impresa edile',
    image: 'siquini_costruzioni.png',
    url: 'http://www.siquini.it',
    active: true,
  },
  {
    id: 19,
    name: 'Studio Tecnico Siquini',
    description: 'Ingegneria civile',
    image: 'studio_siquini.png',
    url: 'http://www.siquini.it',
    active: true,
  },
  {
    id: 20,
    name: 'Tecno S.r.l.',
    description: "Articoli speciali per l'industria",
    image: 'tecno_srl.png',
    url: 'https://tecno-srl.com',
    active: false,
  },
  {
    id: 21,
    name: 'Valentini Emidio',
    description: 'Prodotti ospedalieri',
    image: 'valentini_emidio.png',
    url: 'https://www.paginegialle.it/castignano-ap/agente-commercio/valentini-emidio',
    active: true,
  },
  {
    id: 22,
    name: 'M. & L. Ingegneria',
    description: 'Studio di ingegneria civile',
    image: 'ml_ingegneria.png',
    active: true,
  },
  {
    id: 23,
    name: 'Simonelli Roberta',
    description: 'Onoranze funebri',
    image: 'simonelli.png',
    url: 'https://www.paginegialle.it/simonelliroberta',
    active: true,
  },
  {
    id: 24,
    name: 'Berdini Real Estate',
    description: 'Impresa edile',
    image: 'berdini.png',
    url: 'https://www.berdinirealestate.it',
    active: true,
  },
  {
    id: 25,
    name: 'Vinicola Capriotti',
    description: 'Azienda vinicola',
    image: 'vinicola_capriotti.png',
    active: true,
  },
  {
    id: 26,
    name: 'Italian Luxury Taste',
    description: 'Vendita alimentari Made in Italy',
    image: 'italian_luxury_taste.png',
    url: 'https://www.italianluxurytaste.com/',
    active: true,
  },
  {
    id: 27,
    name: 'Falegnameria Marchigiana',
    description: 'Falegnameria',
    image: 'falegnameria_marchigiana.png',
    url: 'https://www.falegnameriamarchigiana.it/',
    active: true,
  },
  {
    id: 28,
    name: 'Simar Group',
    description: 'Arredamenti',
    image: 'simar.png',
    url: 'https://www.arredosimar.com/',
    active: true,
  },
  {
    id: 29,
    name: 'Esedra',
    description: 'Infissi e sicurezza',
    image: 'esedra.png',
    url: 'https://it-it.facebook.com/people/Esedra/100067990936305/',
    active: true,
  },
  {
    id: 30,
    name: 'NCM2',
    description: 'Materie plastiche',
    image: 'ncm2.png',
    url: 'http://www.ncm2.it/',
    active: true,
  },
  {
    id: 31,
    name: 'Parissi Igino',
    description: 'Trasporto medicinali',
    image: 'parissi_igino.png',
    active: true,
  },
  {
    id: 32,
    name: 'Felici costruzioni',
    description: 'Impresa edile',
    image: 'felici.png',
    url: 'https://impresafelici.it/',
    active: true,
  },
  {
    id: 33,
    name: 'Demetra',
    description: 'Calcestruzzo, movimento terra',
    image: 'demetra.png',
    url: 'https://demetracalcestruzzi.it/',
    active: true,
  },
  {
    id: 34,
    name: 'Sneaknit',
    description: 'Tomaie e pannelli sostenibili',
    image: 'sneaknit.png',
    url: 'https://www.sneaknit.it/',
    active: true,
  },
  {
    id: 35,
    name: 'MelaVerde',
    description: '',
    image: 'melaverde.png',
    active: true,
  },
  {
    id: 36,
    name: 'Paride Iotti',
    description: 'architetto',
    image: 'paride_iotti.png',
    url: 'https://paride85.wixsite.com/architetto',
    active: true,
  },
];
