<template>
  <section id="wrapper">
    <header>
      <div class="inner">
        <h2>Gallery</h2>
        <p>Gli album di Atletico Porchia</p>
      </div>
    </header>

    <div class="wrapper">
      <div class="inner">
        <section class="gallery">
          <div class="box alt">
            <div class="row gtr-uniform">
              <div
                class="col-4 col-12-small"
                v-for="album in gallery"
                :key="album.id"
              >
                <router-link :to="{ name: 'album', params: { id: album.id } }">
                  <h4>{{ album.name }}</h4>
                  <span class="image fit">
                    <img :src="album.cover || '/images/pic06.jpg'" />
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Gallery",
  metaInfo: {
    title: "Gallery",
  },
  data() {
    return {
      gallery: [],
    };
  },
  created() {
    this.$api.getAlbums((response) => {
      this.gallery = response.data.data;
    });
  },
};
</script>

<style scoped>
.gallery h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
