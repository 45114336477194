import Vue from 'vue';
import App from './app/App';

import { router } from './_core/modules/router';
import { api } from './app';

import './_core/modules/meta';
import './_core/modules/jsonld';
import './app/modules/auth';
import './app/modules/gui';

Vue.config.productionTip = false;
if (api) Vue.prototype.$api = api;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
