<template>
  <div id="not-found">
    <section id="wrapper">
      <header>
        <div class="inner">
          <h2>Pagina non trovata</h2>
          <p>La pagina cercata non esiste</p>
        </div>
      </header>
      <div class="wrapper">
        <div class="inner">
          <section>
            <router-link class="button" :to="{ name: 'home' }"
              >torna alla home</router-link
            >
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  metaInfo: {
    title: "Pagina non trovata",
  },
};
</script>

<style scoped>
#not-found .wrapper .inner section {
  padding-bottom: 1.9em;
  text-align: center;
}
#not-found .wrapper .inner section .button {
  padding: 0 3em;
}
</style>