<template>
  <div class="sponsors">
    <section id="wrapper">
      <header>
        <div class="inner">
          <h2>I nostri sponsor</h2>
          <p>tutti i nostri sponsor</p>
        </div>
      </header>
      <div class="wrapper">
        <div class="inner">
          <section class="features">
            <article class="sponsor" v-for="s in sponsors" :key="s.id">
              <a href="#" class="image">
                <img
                  :src="'/images/sponsor/300x200/' + s.image"
                  :srcset="'/images/sponsor/900x600/' + s.image + ' 2x'"
                  :alt="s.name"
                />
              </a>
              <h3 class="major">{{ s.name }}</h3>
              <p>{{ s.description }}</p>
              <a class="special" :href="s.url" target="_blank" v-if="s.url">vai al sito</a>
            </article>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { sponsors } from '../../assets/data/sponsors';

export default {
  name: 'Sponsor',
  metaInfo: {
    title: 'Sponsor',
  },
  data() {
    return {
      sponsors: sponsors
        .filter((item) => item.active)
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        }),
    };
  },
};
</script>

<style scoped>
.features article.sponsor {
  margin: 1em !important;
  padding: 1em 1em 0.1em 1em;
  width: calc(25% - 2em);
}
.features article.sponsor:nth-child(2n) {
  margin-right: 1em;
}
.features article.sponsor .image {
  margin-bottom: 1em;
  margin-left: -1em;
  margin-top: -1em;
  width: calc(100% + 2em);
}
.features article.sponsor .special {
  margin-bottom: 1em;
}
@media (max-width: 1280px) {
  .features article.sponsor {
    width: calc(33.3% - 2em);
  }
}
@media (max-width: 780px) {
  .features article.sponsor {
    width: calc(50% - 2em);
  }
}
@media (max-width: 560px) {
  .features article.sponsor {
    width: calc(100% - 2em);
    margin-bottom: 2em !important;
  }
}
</style>
