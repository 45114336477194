<template>
  <section id="wrapper">
    <header>
      <div class="inner">
        <h2>{{ album.name }}</h2>
        <p>{{ album.description }}</p>
      </div>
    </header>
    <div class="wrapper">
      <div class="inner">
        <section class="album">
          <h3 class="major">{{ album.name }}</h3>
          <div class="box alt">
            <div class="row gtr-uniform">
              <div
                class="col-4 col-12-small"
                v-for="image in album.images"
                :key="image.id"
              >
                <span class="image fit">
                  <img :src="image.url" alt />
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Album",
  metaInfo: {
    title: "Album",
  },
  data() {
    return {
      album: {},
    };
  },
  created() {
    if (!this.$route.params.id) {
      this.$router.push({ name: "gallery" });
    }
    /*this.$api.getAlbum(this.$route.params.id, (response) => { // TODO: correggere bug "TypeError: this.$api is undefined"
      this.album = response.data.data;
    });*/
    this.$api.get("/album/" + this.$route.params.id, (response) => {
      this.album = response.data.data;
    });
  },
};
</script>