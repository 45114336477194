import Home from './routes/Home';
import Sponsors from './routes/Sponsors';
import Players from './routes/Players';
import Staff from './routes/Staff';
import Ranking from './routes/Ranking';
import Gallery from './routes/Gallery';
import Album from './routes/Album';
import NotFound from './routes/NotFound';

export default [
  {
    name: 'home',
    path: '/',
    component: Home
  }, {
    name: 'sponsors',
    path: '/sponsor',
    component: Sponsors
  }, {
    name: 'players',
    path: '/squadra',
    component: Players
  }, {
    name: 'staff',
    path: '/organico',
    component: Staff
  }, {
    name: 'ranking',
    path: '/classifica',
    component: Ranking
  }, {
    name: 'gallery',
    path: '/gallery',
    component: Gallery
  }, {
    name: 'album',
    path: '/album/:id',
    component: Album,
  }, {
    name: 'not-found',
    path: '/404',
    component: NotFound,
  }, {
    name: '404',
    path: '*',
    redirect: { name: 'not-found' }
  },
];