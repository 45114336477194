<template>
  <div class="page-container">
    <page-menu></page-menu>
    <div id="page-wrapper">
      <page-header></page-header>
      <router-view :key="$route.fullPath"></router-view>
      <page-footer></page-footer>
    </div>
  </div>
</template>

<script>
import PageHeader from './components/PageHeader';
import PageMenu from './components/PageMenu';
import PageFooter from './components/PageFooter';

export default {
  name: 'App',
  metaInfo: {
    htmlAttrs: {
      lang: 'it',
    },
    titleTemplate: (title) => {
      return (title ? title + ' | ' : '') + process.env.VUE_APP_TITLE_SHORT;
    },
    meta: [
      { name: 'description', content: process.env.VUE_APP_DESCRIPTION },
      { name: 'keywords', content: process.env.VUE_APP_KEYWORDS },
      { name: 'copyright', content: process.env.VUE_APP_BUSINESS_NAME },
    ],
  },
  /*jsonld: {
    "@context": "https://schema.org",
    "@type": "SportsTeam",
    name: "Atletico Porchia",
    legalName: "A.S.D. Atletico Porchia",
    vatID: "IT02098300441",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Via XXIV Maggio 46",
      postalCode: "63068",
      addressLocality: "Montalto delle Marche",
      addressRegion: "AP",
      addressCountry: "IT",
    },
    image: {
      "@type": "ImageObject",
      contentUrl: "/images/logo.svg",
    },
    sport: "https://www.wikidata.org/wiki/Q2736",
    coach: {
      "@type": "Person",
      name: "Michele Stoppo",
    },
    member: [
      {
        "@type": "OrganizationRole",
        member: [
          {
            "@type": "Person",
            name: "Jacopo Pettirossi",
          },
        ],
        roleName: "Portiere",
      },
      {
        "@type": "OrganizationRole",
        member: [
          {
            "@type": "Person",
            name: "Alessio Pacetti",
          },
        ],
        roleName: "Portiere",
      },
    ],
  },*/
  data() {
    return {};
  },
  mounted() {
    if (process.env.VUE_APP_ANALYTICS_ID) {
      let analytics = document.createElement('script');
      analytics.setAttribute('async', '');
      analytics.setAttribute('defer', '');
      analytics.setAttribute('src', '/a-mdds.js');
      analytics.setAttribute('data-website-id', process.env.VUE_APP_ANALYTICS_ID);
      document.head.appendChild(analytics);
    }
  },
  components: {
    PageHeader,
    PageMenu,
    PageFooter,
  },
};
</script>

<style lang="scss">
@import '../assets/sass/main.scss';
</style>
