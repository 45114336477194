<template>
  <section id="footer">
    <div class="inner">
      <h2 class="major">Contattaci</h2>
      <p>
        Per qualsiasi richiesta potete contattarci tramite il modulo sottostante
      </p>
      <form id="contact_form" v-on:submit="sendMessage">
        <div class="fields">
          <div class="field">
            <label for="name">Nome</label>
            <input
              type="text"
              name="name"
              id="name"
              v-model="contact_form.name"
            />
          </div>
          <div class="field">
            <label for="email">Telefono</label>
            <input
              type="text"
              name="phone"
              id="phone"
              v-model="contact_form.phone"
            />
          </div>
          <div class="field">
            <label for="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              v-model="contact_form.email"
            />
          </div>
          <div class="field">
            <label for="message">Messaggio</label>
            <textarea
              name="message"
              id="message"
              rows="4"
              v-model="contact_form.message"
            ></textarea>
          </div>
        </div>
        <ul class="actions">
          <li>
            <input type="submit" value="invia messaggio" />
          </li>
        </ul>
      </form>
      <ul class="contact">
        <li class="icon solid fa-home">
          <b>{{ company.business_name }}</b>
          <br />
          {{ company.address }}
          <br />
          P.IVA/CF: {{ company.vat_number }}
        </li>
        <li class="icon brands fa-facebook-f" v-if="company.facebook_page">
          <a :href="'https://' + company.facebook_page" target="_blank">{{
            company.facebook_page
          }}</a>
        </li>
      </ul>
      <ul class="copyright">
        <li>&copy; {{ company.business_name }} - Tutti i diritti riservati</li>
        <li v-if="company.privacy_policy">
          <a :href="company.privacy_policy" target="_blank"
            >Informativa sulla privacy</a
          >
        </li>
        <li>
          Design:
          <a href="#">mdds</a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageFooter",
  data() {
    return {
      company: {
        business_name: process.env.VUE_APP_BUSINESS_NAME,
        address: process.env.VUE_APP_BUSINESS_ADDRESS,
        vat_number: process.env.VUE_APP_BUSINESS_VAT_NUMBER,
        facebook_page: "facebook.com/" + process.env.VUE_APP_FACEBOOK_PAGE,
        privacy_policy: process.env.VUE_APP_PRIVACY_POLICY_URL,
      },
      contact_form: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    sendMessage(e) {
      e.preventDefault();
      this.$api.sendMessage(this.contact_form, (response) => {
        console.log(response.data);
      });
    },
  },
};
</script>