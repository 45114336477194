<template>
  <div class="home">
    <!-- Banner -->
    <section id="banner">
      <div class="inner">
        <div class="logo">
          <span></span>
          <!--<span class="icon fa-gem"></span>-->
        </div>
        <h2>{{ title }}</h2>
        <p>{{ description }}</p>
      </div>
    </section>

    <section id="wrapper">
      <!-- sponsor -->
      <section id="one" class="sponsors wrapper spotlight style1">
        <div class="inner">
          <div class="content">
            <h2 class="major">I nostri sponsor</h2>
            <ul>
              <li v-for="s in sponsors" :key="s.id">
                <img :src="'/images/sponsor/300x200/' + s.image" :alt="s.name" />
              </li>
            </ul>
            <p class="disclaimer">
              Tutti i nomi di società, prodotti, marchi e/o loghi pubblicati sul nostro sito sono di
              proprietà esclusiva delle rispettive aziende.
            </p>
            <router-link :to="{ name: 'sponsors' }" class="special"
              >maggiori informazioni</router-link
            >
          </div>
        </div>
      </section>

      <!-- people -->
      <section id="two" class="wrapper alt spotlight style2">
        <div class="inner">
          <div class="content">
            <h2 class="major">La nostra squadra</h2>
            <router-link class="special" :to="{ name: 'players' }">Rosa calciatori</router-link>
            <router-link class="special" :to="{ name: 'ranking' }">Hall of fame</router-link>
            <router-link class="special" :to="{ name: 'staff' }">Organico</router-link>
          </div>
        </div>
      </section>

      <!-- gallery -->
      <section id="three" class="wrapper spotlight style3">
        <div class="inner">
          <div class="content">
            <h2 class="major">Foto</h2>
            <router-link class="special" :to="{ name: 'gallery' }">Gallery</router-link>
          </div>
        </div>
      </section>

      <!-- post -->
      <section id="four" class="wrapper alt style1">
        <div class="inner">
          <h2 class="major">ultime notizie</h2>
          <p>Gli ultimi aggiornamenti sull'Atletico Porchia</p>
          <section class="features">
            <article v-for="post in posts" :key="post.id">
              <a :href="post.url" target="_blank" class="image">
                <img
                  :src="post.image ? post.image : '/images/post_image.svg'"
                  alt="immagine del post"
                  :style="post.aspect_ratio ? 'aspect-ratio:' + post.aspect_ratio : ''"
                />
              </a>
              <h3 class="major">{{ dateFormat(post.date) }}</h3>
              <p class="post_message">{{ post.message }}</p>
              <a :href="post.url" target="_blank" class="special">vai al post</a>
            </article>
          </section>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { sponsors } from '../../assets/data/sponsors';

export default {
  name: 'Home',
  metaInfo: {
    title: 'Home',
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      description: process.env.VUE_APP_DESCRIPTION,
      posts: [],
      sponsors: sponsors
        .filter((item) => item.active)
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        }),
    };
  },
  created() {
    this.$api.getPosts((response) => {
      this.posts = response.data.data;
    });
  },
  methods: {
    dateFormat(date) {
      let d = new Date(date);
      return (
        ('0' + d.getDate()).slice(-2) +
        '/' +
        ('0' + (d.getMonth() + 1)).slice(-2) +
        '/' +
        d.getFullYear()
      );
    },
  },
};
</script>

<style scoped>
.wrapper .inner {
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.logo span {
  display: block;
  width: 128px;
  height: 128px;
  background: url('/images/logo.svg');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.sponsors * {
  text-align: center;
}
.sponsors h2 {
  text-align: right;
}
.sponsors ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5em 0 0;
  margin-bottom: 0.5em;
}
.sponsors ul li {
  width: 12.5%;
}
.sponsors ul li img {
  width: 100%;
  border-radius: 0.25em;
}
.sponsors .disclaimer {
  font-size: 0.8em;
}

@media (max-width: 1280px) {
  .sponsors ul li {
    width: 25%;
  }
}

.post_message {
  white-space: pre-wrap;
}
</style>
