<template>
  <header id="header" class="alt">
    <h1>
      <router-link :to="{ name: 'home' }">{{ title }}</router-link>
    </h1>
    <nav>
      <a href="#menu" v-on:click="toggleMenu">Menu</a>
    </nav>
  </header>
</template>

<script>
export default {
  name: "PageHeader",
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 300) {
        document.getElementById("header").classList.remove("alt");
      } else {
        document.getElementById("header").classList.add("alt");
      }
    },
    toggleMenu() {
      let body = document.getElementById("body");
      if (body.classList.contains("is-menu-visible")) {
        body.classList.add("is-menu-visible");
      } else {
        body.classList.remove("is-menu-visible");
      }
    },
  },
};
</script>