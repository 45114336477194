import axios from 'axios';

export class ApiClientCore {
  requestInterceptor = null;

  constructor(baseUrl) {
    let service = axios.create({
      baseURL: baseUrl
    });
    if (this.requestInterceptor) {
      service.interceptors.request.use(this.requestInterceptor);
    }
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }
  getCookieValue(a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
  }
  handleSuccess(response) {
    return response;
  }
  handleError = (error) => {
    return Promise.reject(error)
  }
  get(path, callback) {
    return this.service.get(path, {
      validateStatus: function (status) {
        return status < 500; // Reject if status code is greater than or equal to 500
      }
    }).then(
      (response) => callback(response)
    );
  }
  put(path, payload, callback) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((response) => callback(response));
  }
  post(path, payload, callback) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    }).then((response) => callback(response));
  }
  delete(path, payload, callback) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response));
  }
  uploadFile(scope, resource_id, files, callback) {
    let formData = new FormData();
    formData.append("scope", scope);
    formData.append("resource_id", resource_id);
    for (var i = 0; i < files.length; i++) {
      formData.append("files[" + i + "]", files[i]);
    }
    return this.service.request({
      method: 'POST',
      url: 'file/upload',
      responseType: 'json',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => callback(response));
  }
}
