import { ApiClientCore } from '../../_core/classes/ApiClientCore'

export class ApiClient extends ApiClientCore {
  /**
   * Restituisce gli ultimi post della pagina Facebook
   * @callback responseCallback
   * @param {responseCallback} callback
   */
  getPosts(callback) {
    return this.get("/post", callback);
  }
  /**
   * Restituisce l'elenco degli album della pagina Facebook
   * @callback responseCallback
   * @param {responseCallback} callback
   */
  getAlbums(callback) {
    return this.get("/album", callback);
  }
  /**
   * Restituisce l'album con l'id specificato dalla pagina Facebook
   * @callback responseCallback
   * @param {string} id
   * @param {responseCallback} callback
   */
  getAlbum(id, callback) {
    return this.$api.get("/album/" + id, callback);
  }
  /**
   * Invia un messaggio all'indirizzo email del gestore
   */
  sendMessage(data, callback) {
    return this.post("/message", data, callback);
  }
}
