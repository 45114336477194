<template>
  <div id="players" class="people">
    <section id="wrapper">
      <header>
        <div class="inner">
          <h2>Rosa calciatori</h2>
          <p>Stagione {{ season }}</p>
        </div>
      </header>
      <div class="wrapper">
        <div class="inner">
          <section class="person_list">
            <select name="season" v-model="season">
              <option v-for="(f, key) in formations" :key="key" :value="key">
                {{ 'Stagione ' + key }}
              </option>
            </select>
            <div class="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="p in formations[season]" :key="p.id">
                    <td class="person_image">
                      <img
                        :srcset="image_urls(people[p.id].image_id)"
                        :src="image_url(people[p.id].image_id)"
                        :alt="person_name(people[p.id])"
                      />
                    </td>
                    <td class="person_info">
                      <b>{{ person_name(people[p.id]) }}</b>
                      <br />
                      {{ p.role }}
                      <br />
                      <a
                        v-if="people[p.id].url"
                        :href="info_url + people[p.id].url"
                        target="_blank"
                        rel="noopener"
                      >
                        <i class="fa fa-info-circle" />info
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import people from '../../assets/data/people.json';
import formations from '../../assets/data/formations.json';

export default {
  name: 'Players',
  metaInfo: {
    title: 'Squadra',
  },
  data() {
    return {
      people: people,
      formations: formations,
      season: Object.keys(formations)[0] ?? '',
      info_url: 'https://www.marcheingol.it/soggetto/',
      image_path: '/images/people/',
      image_res: [512, 1024],
    };
  },
  methods: {
    image_urls(id) {
      if (id == null) return this.image_path + 'empty.svg';
      return this.image_res
        .map((res) => this.image_path + res + '/' + id + '.jpg ' + res + 'w')
        .join(',');
    },
    image_url(id) {
      if (id == null) return this.image_path + 'empty.svg';
      return this.image_path + this.image_res[0] + '/' + id + '.jpg';
    },
    person_name(person) {
      if (person == null) return '';
      return person.last_name + ' ' + person.first_name;
    },
  },
};
</script>

<style scoped>
/* spostare in css condiviso */
.people .wrapper .inner {
  padding: 0.5em;
}
.people select {
  margin-bottom: 2em;
}
.people table td {
  vertical-align: middle;
  padding: 1em 0.5em;
}
.people table td.person_image {
  width: 10em;
}
.people table td.person_image img {
  width: 8em;
}
.people table td.person_info a {
  font-size: 0.8rem;
}
.people table td.person_info a i {
  margin-right: 0.5em;
}

@media (max-width: 480px) {
  .people table td.person_image {
    width: 7em;
  }
  .people table td.person_image img {
    width: 5em;
  }
}
</style>
